import React from "react";

export const Image = ({ title, smallImage, onClick }) => {
  return (
    <div className="product-item" onClick={onClick}>
      <div className="hover-bg">
        <div className="hover-text">
          <h4>{title}</h4>
        </div>
        <img src={smallImage} className="img-responsive" alt={title} />{" "}
      </div>
    </div>
  );
};
