import { Image } from "./Image";
import React, { useState } from "react";
import { ProductDetail } from "./ProductDetail";

export const Product = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const [productId, setProductId] = useState()
  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setIsOpen(false)
  }
  const openModal = (id) => {
    document.body.style.overflow = 'hidden';
    setProductId(id)
    setIsOpen(true)
  }

  const handleClick = (d) => {
    openModal(d.id);
  };

  return (
    <div id="product" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Product</h2>
          <p>
            Premium seaweed with 40 years of tradition in the clean region of the West Sea
          </p>
        </div>
        <div className="row">
          <div className="product-items">
            {props.data
              ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-6 col-sm-6 col-md-4 col-lg-4"
                >
                  <Image
                    title={d.title}
                    largeImage={d.largeImage}
                    smallImage={d.smallImage}
                    onClick={() => handleClick(d)}
                  />
                </div>
              ))
              : "Loading..."}
            <ProductDetail isOpen={isOpen} closeModal={closeModal} products={props.data} productId={productId} />

          </div>
        </div>
      </div>
    </div>
  );
};
