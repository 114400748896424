import React from "react";
import Modal from "react-modal";

export const ProductDetail = ({ isOpen, closeModal, products, productId }) => {
    const style = {
        overlay: {
            zIndex: 10000
        }
    };

    const product = products?.filter(products => products.id === productId)[0]

    return (
        <Modal
            className={"productDetail scrollbar-hide"}
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={style}
            ariaHideApp={false}
        >
            <div className="detailContainer">
                <div className="detailTitle">{product?.detail?.title}</div>
                <div className="detailSubtitle">{product?.detail?.subtitle}</div>
                <img src={product?.smallImage} className="detailImg img-responsive" alt={product?.title} />
                <div className="detailHeaderDescription">Description</div>
                <div className="detailDescription">{product?.detail?.description}</div>
                <ol>
                    {product?.detail?.detailedDescription.map((item, index) => (
                        <li key={index}>
                            {item}
                        </li>
                    ))}
                </ol>
            </div>
        </Modal>
    );
};
